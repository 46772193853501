@use "sass:meta" as ---ullwtt3r34q;@use 'sass:math';

.post {
  &__text {
    @include grid-column-margin;
  }

  &__files {
    margin-bottom: $global-margin;

    ul {
      margin-left: 0;
      margin-bottom: 0;
      list-style: none;
    }

    li {
      margin-bottom: math.div($global-margin, 2);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__brands {
    margin-bottom: $global-margin;
  }

  &__condition {
    margin-bottom: $global-margin;
  }

  &__map {
    margin-bottom: $global-margin;
  }
}

#postMap {
  @include grid-column-margin;

  height: 350px;
  width: 100%;
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3775");