@use "sass:meta" as ---70mow6mkfwa;.doska-post {
  .content-future {
    &__header {
      .title {
        display: flex;
        gap: 10px;

        @include breakpoint(small only) {
          margin-top: 1rem;
        }
      }
    }
  }

  figure {
    display: flex;
    justify-content: center;
  }

  &__content {
    margin-bottom: 1rem;

    h2, h3, h4, h5, h6 {
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        border: none;
        padding: inherit;
        background-color: inherit;
      }
    }

    h2 {
      font-size: rem-calc(24);
      margin-bottom: 1rem;
    }

    h3 {
      font-size: rem-calc(22);
    }

    h4 {
      font-size: rem-calc(20);
    }

    h5 {
      font-size: rem-calc(18);
    }

    h6 {
      font-size: rem-calc(16);
    }

    .address {
      margin-bottom: rem-calc(26);

      .geo {
        display: flex;
        gap: 10px;

        @include breakpoint(small only) {
          flex-direction: column;
        }

        @include breakpoint(medium) {
          justify-content: space-between;
          align-items: center;
        }

        &-map {
          background-color: $alice-blue;
          border: 1px solid #ebeaea;
          padding: 8px 14px;
          border-radius: $global-radius;
          font-size: rem-calc(14);
          flex-shrink: 0;

          @include breakpoint(small only) {
            width: fit-content;
          }
        }
      }

      #showMap {
        border-radius: $global-radius;
        border: 1px solid #ebeaea;
        height: 350px;
        margin-top: 1rem;
        overflow: hidden;
        width: 100%;
      }
    }

    .attfiles {
      margin-bottom: rem-calc(26);

      li {
        margin-bottom: .6rem;
      }
    }

    .description {
      margin-bottom: rem-calc(26);
    }

    .fotorama {
      &__stage {
        margin-bottom: $global-margin;
      }

      &__thumb {
        background-color: transparent;

        &-border {
          display: none;
        }
      }

      &__caption {
        //background-color: rgba(47, 47, 47, .5);

        &__wrap {
          background-color: rgb(47 47 47 / 50%);
          color: #fff;
          float: right;
        }
      }

      &__nav {
        &__frame {
          border: solid 1px $medium-gray;
          opacity: .5;
          margin: 0 .5rem;

          &:hover,
          &.fotorama__active {
            border: solid 1px $primary-color;
            opacity: 1;
          }
        }
      }

      &__video {
        top: initial;
        right: initial;
        bottom: initial;
        left: initial;
        position: initial;
      }
    }

    .pictures {
      margin-bottom: rem-calc(26);
    }

    .properties {
      margin-bottom: rem-calc(26);

      li {
        margin-bottom: .6rem;
      }
    }
  }

  &__contacts {
    @include breakpoint(medium) {
      @include xy-cell(36%, $gutter-position: left, $breakpoint: 'auto');
    }

    @include breakpoint(large) {
      @include xy-cell(30%, $gutter-position: left, $breakpoint: 'auto');
    }

    .wrapper {
      gap: 2rem;
      padding-bottom: $global-padding;
    }

    .actions {
      .button {
        font-weight: bold;
        font-size: rem-calc(18);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .send-message {
        @include button-style(map_get($foundation-palette, olivine), auto, $white);

        .user-online {
          font-size: rem-calc(13);
          font-weight: $global-weight-normal;
          margin-top: .5rem;

          i {
            color: #390 !important;
          }
        }
      }

      .get-phone {
        color: $light-black;
      }

      .phone {
        font-size: rem-calc(24);
        font-weight: $global-weight-bold;
        text-align: center;

        &::after {
          content: 'Сообщите, что нашли информацию на сайте «Элек.ру»';
          display: block;
          font-size: rem-calc(12);
          padding-top: 4px;
          color: $dark-gray;
          line-height: 1.1;
          font-weight: $global-weight-normal;
        }
      }
    }

    .user {
      border-radius: $global-radius;
      box-shadow: 0 2px 6px 0 #2e319117;
      display: flex;
      flex-direction: column;
      gap: $global-margin;
      padding: 10px;

      .button {
        margin: 0;
      }

      .title {
        font-weight: bold;
      }

      .partition {
        font-size: rem-calc(14);
      }

      .show-more {
        background-color: transparent;
        border: 1px solid map-get($foundation-palette, san-marino);
        color: map-get($foundation-palette, san-marino);

        &:hover {
          background-color: map-get($foundation-palette, san-marino);
          color: $white;
        }
      }

      .subscribe-user {
        display: flex;
        gap: 10px;

        .follow {
          flex-grow: 1;
        }

        .stoplist {
          .button {
            width: 38px;
            height: 100%;
            border-radius: $global-radius;
            background-image: url('./svg/stoplist.svg');
            background-size: 24px;
            background-position: center;
            background-color: $light-gray;
            background-repeat: no-repeat;

            span {
              position: absolute;
              width: 1px;
              height: 1px;
              clip: rect(1px, 1px, 1px, 1px);
            }
          }

          .is-banned {
            background-color: $service;
          }
        }
      }
    }

    .company {
      .logo {
        display: flex;
        justify-content: center;
        margin: 1rem 0;

        &__wrapper {
          flex-shrink: 0;
          max-width: 5rem;
          width: 5rem;

          @include breakpoint(medium) {
            align-self: center;
            max-width: 8rem;
            width: 8rem;
          }
        }
      }

      .title {
        font-weight: bold;
        margin-bottom: .5rem;
      }

      .tizer {
        font-size: rem-calc(13);
      }

      .more {
        ul {
          font-size: rem-calc(14);

          ::marker, a {
            color: map-get($foundation-palette, success);
          }

          a {
            &:hover {
              color: $service;
            }
          }
        }
      }
    }
  }

  &__sidebar {
    flex: 0 0 20%;
    max-width: 20%;

    @include breakpoint(large down) {
      display: none;
    }

    #bs22,
    #bs51,
    #bs52,
    #bs53 {
      @include grid-column-margin;
    }
  }

  &__similar {
    margin-bottom: 1rem;

    .h3 {
      margin-bottom: 1rem;
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3749");