@use "sass:meta" as ---ullwtt3r34q;.doska-list {
  &.cell {
    flex: 1 1 0;
    width: auto;

    @include breakpoint(small) {
      order: 2;
    }

    @include breakpoint(large) {
      order: 1;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    h1 {
      margin-bottom: 1rem;
    }

    small {
      font-weight: $global-weight-bolder;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .is-commerce {
      background-color: #f8f6e7;
      border-bottom: none;
      border-radius: $global-radius;
      margin: 1rem -1rem;
      padding: 1rem;
    }

    .post-item {
      padding: .8rem;
      display: flex;
      gap: 1.5rem;
      overflow: hidden;
      min-height: 180px;
      transition: .3s;

      &:hover {
        background-color: $alice-blue;

        @include breakpoint(large) {
          .contacts {
            .action  {
              display: block;
            }
          }
        }
      }

      &__commerce {
        border-radius: $global-radius;
        border: 1px solid $alice-blue;
        border-bottom: 10px solid $alice-blue;

        &:hover {
          background-color: #f8f6e7;
        }

        .pictures {
          display: grid;
          gap: 6px;
          grid-template-columns: 160px;
          margin-bottom: .5rem;

          @include breakpoint(small only) {
            grid-template-columns: 100px;
          }

          &__main {
            img {
              aspect-ratio: 4/3;
              border-radius: $global-radius;
              width: 160px;
            }
          }

          &__mini {
            display: grid;
            gap: 6px;

            @include breakpoint(medium) {
              grid-auto-flow: column;
              justify-content: space-between;
            }

            img {
              aspect-ratio: 4/3;
              border-radius: $global-radius;
              width: 80px;
            }
          }
        }
      }

      .img {
        width: 160px;
        display: flex;
        align-items: flex-start;
        margin-bottom: .5rem;

        @include breakpoint(small only) {
          width: 100px;
        }

        img {
          border-radius: $global-radius;
          width: 100%;
        }

        .plug {
          background-color: $light-gray;
          width: 100%;
          aspect-ratio: 4/3;
          border-radius: $global-radius;
          border: 1px solid $medium-gray;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: rem-calc(13);
            font-weight: $global-weight-bold;
            color: $dark-gray;

            @include breakpoint(small only) {
              font-size: rem-calc(10);
            }
          }
        }
      }

      .condition {
        display: inline-block;
        padding: 2px 6px;
        border-radius: $global-radius;
        margin-right: .2rem;
        font-size: rem-calc(13);
        font-weight: $global-weight-normal;
        color: $white;

        &.v1 {
          background-color: #8ac926;
        }

        &.v2 {
          background-color: #ffca3a;
        }

        &.v3 {
          background-color: #ff595e;
        }
      }

      .description {
        display: flex;
        width: 100%;
        gap: 1.5rem;

        @include breakpoint(small only) {
          flex-direction: column;
        }
      }

      .offer {
        flex-grow: 1;

        .title {
          font-weight: $global-weight-bold;
          margin-bottom: .6rem;
          overflow: hidden;
          display: -webkit-box; /* stylelint-disable-line */
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &__wrapper {
            display: flex;
            justify-content: space-between;
            gap: 6px;
          }
        }

        .favorites {
          flex-shrink: 0;
        }

        .info {
          font-size: rem-calc(14);
          line-height: 1.4;
          margin-bottom: .6rem;
          color: map-get($foundation-palette, secondary);
          display: -webkit-box; /* stylelint-disable-line */
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
        }

        .geo, .date {
          font-size: rem-calc(13);
          color: map-get($foundation-palette, secondary);
        }
      }

      .contacts {
        flex-shrink: 0;
        padding-top: .2rem;
        color: $black;

        @include breakpoint(medium) {
          width: 200px;
        }

        .user, .company {
          font-size: rem-calc(13);
          display: flex;
          align-items: baseline;
          gap: 0 6px;

          a {
            color: #000;
            font-weight: $global-weight-bolder;
          }

          &::before {
            content: '';
            display: block;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
          }
        }

        .user {
          &::before {
            background-image: url('../../../styles/common/svg/user-name.svg');
          }
        }

        .company {
          flex-wrap: wrap;

          &::before {
            background-image: url('../../../styles/common/svg/company-name.svg');
          }
        }

        .action {
          @include breakpoint(large) {
            display: none;
          }

          .button {
            background: transparent;
            border-color: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, primary);
            margin: .8rem 0 0;

            &:hover {
              background-color: map-get($foundation-palette, primary);
              color: $white;
            }
          }

          .phone {
            font-size: rem-calc(14);
          }
        }
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3742");