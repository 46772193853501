@use "sass:meta" as ---ry84wxtmkt8;.doska-filter {
  background-color: $alice-blue;
  border-radius: $global-radius;
  margin: 0 0 1rem;
  padding: 1rem;

  @include breakpoint(small only) {
    padding-top: 12px;
  }

  .filter_item {
    margin: 0;
    padding: 14px 10px;

    @include breakpoint(small only) {
      padding: 0;
      margin-bottom: 20px;
    }

    @include breakpoint(medium only) {
      padding: 7px 10px;
    }

    &:not(:first-child) {
      border-top: 1px solid $medium-gray;
    }

    .title {
      padding: 4px 8px;
      font-weight: $global-weight-bold;
      color: $dark-gray;
    }

    .type {
      display: flex;
      list-style: none;
      margin: 0;
    }

    @include breakpoint(small only) {
      .column.items {
        flex-basis: content;
        padding: 0;
      }
    }

    .items {
      @include breakpoint(medium only) {
        padding-bottom: 8px;
      }

      & > ul {
        display: flex;
        flex-wrap: wrap;
        font-size: rem-calc(14);
        list-style: none;
        margin: 0;

        li {
          padding: 4px 8px;

          &.hide-item {
            display: none;
          }

          @include breakpoint(small only) {
            margin: 2px;
          }

          &.selected {
            color: map-get($foundation-palette, primary);
            padding: 4px 8px;
            background-color: $white;
            border-radius: $global-radius;
            box-shadow: 0 3px 2px 0 map-get($foundation-palette, grayish-blue);
          }
        }
      }

      .show-more {
        cursor: pointer;
        color: $dark-gray;
        font-size: 12px;
        border: 1px solid #bdbfc1;
        border-radius: $global-radius;
        align-self: center;
        transition: .25s;
        margin-left: 1rem;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  #collapse.rubrics {
    background-color: transparent;
  }

  .m-menu-5 {
    padding: 0;

    @include breakpoint(medium only) {
      ul {
        font-size: rem-calc(13);
      }
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3743");